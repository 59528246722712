<template>
	<div>
		<div class="m-2">
			<div class="break-words">
				<div class="relative p-4 grid gap-6">
					<div class="flex justify-between">
						<component :is="icon" />
						<div>
							<a :href="file" :download="data.source_group_name" class="mr-5">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="inline w-6 h-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke="#2563eb"
										stroke-width="2"
										d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
									/>
								</svg>
							</a>

							<a :href="data.external_url" target="_black" rel="noopener noreferrer">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="inline w-6 h-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke="#2563eb"
										stroke-width="2"
										d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
									/>
								</svg>
							</a>
						</div>
					</div>
					<div class="flex flex-col items-start p-3 -m-3 rounded-lg">
						<div class="ml-4">
							<div
								v-if="hasTitle"
								v-html="xss(data.title)"
								class="mb-5 text-base font-medium text-gray-900 uppercase"
							/>
							<div class="flex flex-wrap justify-between">
								<div class="mb-3 italic font-normal">
									{{ data.source_group_name }}
								</div>
								<span class="text-sm">{{ formatDate }}</span>
							</div>
							<div v-if="data.is_forward" class="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									viewBox="0 0 16 16"
									class="inline"
								>
									<path
										fill-rule="evenodd"
										d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z"
									/>
								</svg>
								<i>{{ data.forward_channel_name }}</i>
							</div>

							<div v-html="content" class="mt-1 text-sm details" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import xss from "@/config/xss";
import formatHtml from "@/utils/formatHtml";
export default {
	name: "PostDetails",
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		xss,
	},
	computed: {
		hasTitle() {
			const {
				data: { source },
			} = this;

			return source !== "telegram" && source !== "twitter";
		},
		formatDate() {
			const {
				data: { date },
			} = this;
			const dateInstance = new Date(date);
			return (
				dateInstance.toLocaleDateString() + " " + dateInstance.toLocaleTimeString()
			);
		},
		icon() {
			const {
				data: { source },
			} = this;
			return () => import(`./icons/${source}.vue`);
		},
		file() {
			const {
				data: { content },
			} = this;
			return `data:text/html,${encodeURIComponent(content)}`;
		},
		content() {
			return formatHtml(xss(this.data.content));
		},
	},
};
</script>
