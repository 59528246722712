<template>
	<div>
		<form @submit.prevent="search" @input="onFormInput" class="mt-10 sm:mt-0">
			<div class="md:grid md:grid-cols-2 md:gap-6">
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div>
						<div class="ml-0 overflow-hidden">
							<div class="bg-white space-y-6">
								<fieldset>
									<legend class="text-base font-medium text-gray-900"></legend>
									<div class="mt-4 mb-2 ml-2">
										<div class="flex">
											<input
												v-model="filter.search"
												id="search"
												name="search"
												type="search"
												class="inline-flex w-full border-r-0 border-gray-300 rounded rounded-r-none focus:ring-blue-500"
											/>
											<button
												@click.prevent=""
												type="submit"
												class="relative inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-300 border border-l-0 border-gray-300 rounded-l-none cursor-default rounded-md"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="w-6 h-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
													/>
												</svg>
											</button>
										</div>
										<div class="flex mt-2 mb-2">
											<div class="inline-flex mr-2">
												<select
													v-model="filter.order_by"
													id="order_by"
													name="order_by"
													class="block px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm my-w"
												>
													<option
														v-for="value in options.order_by"
														:value="value"
														:key="value"
														>{{ $i18n(`filter.${value}`) }}</option
													>
												</select>
											</div>
											<div class="inline-flex">
												<select
													v-model="filter.order_type"
													id="order_type"
													name="order_type"
													class="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm my-w"
												>
													<option
														v-for="value in options.order_type"
														:value="value"
														:key="value"
														>{{ $i18n(`filter.${value}`) }}</option
													>
												</select>
											</div>
										</div>
									</div>
									<div class="flex mb-5 ml-2">
										<div>
											<div class="p-2 mr-2 bg-gray-100 rounded">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="w-6 h-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
													/>
												</svg>
											</div>
										</div>
										<div class="inline-flex mr-2 my-w-2" style="max-width:131px">
											<t-datepicker
												v-model="filter.after"
												@change="debouncedSearch"
												user-format="d.m.Y"
												date-format="Z"
												:close-on-select="true"
											/>
										</div>
										<div class="inline-flex" style="max-width:180px">
											<t-datepicker
												v-model="filter.before"
												@change="debouncedSearch"
												date-format="Z"
												user-format="d.m.Y"
												:close-on-select="true"
											/>
										</div>
									</div>

									<div class="pl-2 theme-2">
										<legend
											class="text-base font-medium text-gray-900"
										>
											{{ $i18n("source.type") }}
										</legend>
										<div class="mt-2 mb-5 space-y-4">
											<span
												@click="checkAllSourcesTypes"
												class="inline-block underline cursor-pointer"
												>{{ $i18n("action.check-all") }}</span
											>
											&nbsp;
											<span
												@click="uncheckAllSourcesTypes"
												class="underline cursor-pointer"
												>{{ $i18n("action.uncheck-all") }}</span
											>
											<div class="flex">
												<div class="pr-5 space-y-4">
													<div
														v-for="{ value, qty } in filteredSourcesTypes"
														:key="`sourceType${value}`"
														class="flex items-start"
													>
														<div class="flex items-center h-5 mr-2">
															<input
																:name="`source-type${value}`"
																:id="`source-type${value}`"
																:value="value"
																v-model="filter.source"
																type="checkbox"
																class="w-4 h-4 text-blue-600 border-gray-300 rounded cursor-pointer focus:ring-blue-500"
															/>
														</div>
														<div class="ml-3 text-sm truncate w-60">
															<label
																:for="`source-type${value}`"
																:title="value"
																class="font-medium text-gray-700"
																>{{ value }}</label
															>
														</div>
														<div class="ml-3 text-xs">
															<span class="font-medium text-gray-700">
																{{ qty }}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<legend
											class="text-base font-medium text-gray-900"
										>
											{{ $i18n("source.titles") }}
										</legend>
										<div class="mt-4 space-y-4">
											<div>
												<div class="flex items-center h-5 pr-2">
													<input
														@input.stop=""
														v-model="searchSources"
														id="search-sources"
														name="search-sources"
														type="search"
														class="w-full border-gray-300 rounded focus:ring-blue-500"
													/>
												</div>
											</div>
										</div>
										<div class="mt-4 mb-5 space-y-4">
											<span
												@click="checkAllSources"
												class="inline-block underline cursor-pointer"
												>{{ $i18n("action.check-all") }}</span
											>
											&nbsp;
											<span
												@click="uncheckAllSources"
												class="underline cursor-pointer"
												>{{ $i18n("action.uncheck-all") }}</span
											>

											<div class="flex">
												<div class="space-y-4">
													<div
														v-for="{ id, external_id, name, qty } in filteredSources"
														:key="`source${id}`"
														class="flex items-start"
													>
														<div class="flex items-center h-5">
															<input
																:name="`source${id}`"
																:id="`source${id}`"
																:value="external_id"
																v-model="filter.source_group_id"
																type="checkbox"
																class="w-4 h-4 text-blue-600 border-gray-300 rounded cursor-pointer focus:ring-blue-500"
															/>
														</div>
														<div class="ml-3 text-sm truncate w-60">
															<label
																:for="`source${id}`"
																:title="name"
																class="font-medium text-gray-700"
																>{{ name }}</label
															>
														</div>
														<div class="ml-3 text-xs">
															<span class="font-medium text-gray-700">
																{{ qty }}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<legend
											class="text-base font-medium text-gray-900"
										>
											{{ $i18n("keyword.titles") }}
										</legend>
										<div class="mt-4 space-y-4">
											<div>
												<div class="flex items-center h-5 pr-2">
													<input
														@input.stop=""
														v-model="searchKeywords"
														id="search-keyword"
														name="search-keyword"
														type="search"
														class="w-full border-gray-300 rounded focus:ring-blue-500"
													/>
												</div>
											</div>
										</div>
										<div class="mt-4 space-y-4">
											<span
												@click="checkAllKeywords"
												class="inline-block underline cursor-pointer"
												>{{ $i18n("action.check-all") }}</span
											>
											&nbsp;
											<span
												@click="uncheckAllKeywords"
												class="underline cursor-pointer"
												>{{ $i18n("action.uncheck-all") }}</span
											>

											<div class="flex">
												<div class="space-y-4">
													<div
														v-for="{ value, qty } in filteredKeywords"
														:key="`keywords${value}`"
														class="flex items-start"
													>
														<div class="flex items-center h-5">
															<input
																:name="`keywords${value}`"
																:id="`keywords${value}`"
																:value="value"
																v-model="filter.keywords"
																type="checkbox"
																class="w-4 h-4 text-blue-600 border-gray-300 rounded cursor-pointer focus:ring-blue-500"
															/>
														</div>
														<div class="ml-3 text-sm truncate w-60">
															<label
																:title="value"
																:for="`keywords${value}`"
																class="font-medium text-gray-700"
															>
																{{ value }}
															</label>
														</div>
														<div class="ml-3 text-xs">
															<span class="font-medium text-gray-700">
																{{ qty }}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import debounce from "@/utils/debounce";
import { mapGetters } from "vuex";

export default {
	name: "PostsFilter",
	props: {
		feedId: {
			type: String,
		},
		keywords: {
			type: Array,
			default() {
				return [];
			},
		},
		sources: {
			type: Array,
			default() {
				return [];
			},
		},
		sourcesTypes: {
			type: Array,
			default() {
				return [];
			},
		},
		default: {
			type: Object,
			default: () => ({
				order_by: "cdate",
				order_type: "DESC",
				search: undefined,
			}),
		},
		defaultOptions: {
			type: Object,
			default: () => ({
				order_by: ["date", "cdate"],
				order_type: ["ASC", "DESC"],
			}),
		},
		resultsCount: {
			type: Number,
			default: 0,
		},
	},
	data() {
		const {
			default: { order_by, order_type, search },
			defaultOptions,
		} = this;
		return {
			filter: {
				order_by,
				order_type,
				search,
				source_group_id: [],
				keywords: [],
				source: [],
				before: undefined,
				after: undefined,
			},
			options: {
				order_by: defaultOptions.order_by,
				order_type: defaultOptions.order_type,
			},
			isFilterUpdated: false,
			show: true,
			searchKeywords: "",
			searchSources: "",
			searchSourcesTypes: "",
			userActivity: false,
		};
	},
	computed: {
		...mapGetters("posts", ["postsLoad"]),
		filteredKeywords() {
			return this.keywords
				.map(({ value, qty }) => ({ value, qty }))
				.filter(({ value }) => {
					return new RegExp(this.searchKeywords, "gi").test(value);
				});
		},
		filteredSources() {
			return this.sources
				.map(({ id, external_id, name, qty }) => ({ id, external_id, name, qty }))
				.filter(({ name }) => {
					return new RegExp(this.searchSources, "gi").test(name);
				});
		},
		filteredSourcesTypes() {
			return this.sourcesTypes.map(({ value, qty }) => ({ value, qty }));
		},
	},
	watch: {
		filter: {
			deep: true,
			async handler() {
				this.isFilterUpdated = true;
			},
		},
	},
	methods: {
		async search() {
			try {
				if (this.isFilterUpdated) {
					const {
						$router,
						$route,
						$route: { query },
						$parent,
					} = this;
					await $router.replace({
						...$route,
						query: { ...query, ...this.filter },
					});

					this.isFilterUpdated = false;
					$parent.$emit("Filter", this.filter.search);
				}
			} catch (error) {
				console.debug(error);
			}
		},
		syncFilter() {
			try {
				const {
					$route: { query },
				} = this;
				if (query && Object.keys(query).length) {
					this.filter = { ...this.filter, ...query };
				}
			} catch (error) {
				console.debug(error);
			}
		},
		toggleSearch() {
			try {
				this.show = !this.show;
			} catch (error) {
				console.debug(error);
			}
		},
		checkAllSources({ withSearch = true } = {}) {
			this.filter.source_group_id = this.sources.map(
				({ external_id }) => external_id
			);
			this.isFilterUpdated = true;
			if (withSearch) this.debouncedSearch();
		},
		checkAllKeywords({ withSearch = true } = {}) {
			this.filter.keywords = this.keywords.map(({ value }) => value);
			this.isFilterUpdated = true;
			if (withSearch) this.debouncedSearch();
		},
		uncheckAllSources() {
			this.filter.source_group_id = [];
			this.isFilterUpdated = true;
			this.debouncedSearch();
		},
		uncheckAllKeywords() {
			this.filter.keywords = [];
			this.isFilterUpdated = true;
			this.debouncedSearch();
		},
		checkAllSourcesTypes({ withSearch = true } = {}) {
			this.filter.source = this.sourcesTypes.map(({ value }) => value);
			this.isFilterUpdated = true;
			if (withSearch) this.debouncedSearch();
		},
		uncheckAllSourcesTypes() {
			this.filter.source = [];
			this.isFilterUpdated = true;
			this.debouncedSearch();
		},
		setUserActivity() {
			this.userActivity = true;
		},
		onFormInput() {
			this.setUserActivity();
			this.debouncedSearch();
		},
	},
	created() {
		try {
			const {
				default: { order_by, order_type, search },
				$route: {
					query: { source_group_id },
				},
			} = this;

			const unwatchSources = this.$watch("sources", () => {
				try {
					if (!source_group_id) this.checkAllSources({ withSearch: false });

					unwatchSources();
				} catch (error) {
					console.debug(error);
				}
			});

			const unwatchSourcesTypes = this.$watch("sourcesTypes", () => {
				try {
					this.checkAllSourcesTypes({ withSearch: false });

					unwatchSourcesTypes();
				} catch (error) {
					console.debug(error);
				}
			});

			this.syncFilter();

			this.filter = { ...this.filter, order_by, order_type, search };

			this.debouncedSearch = debounce(this.search, 500);
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
<style scoped>
.theme-2 {
	height: 100%;
	min-height: calc(100vh - 360px);
	max-height: calc(100vh - 360px);
	overflow-y: auto;
}
.my-w {
	min-width: 180px;
}
</style>
