import xss from 'xss';
export default html => {
    return xss(html, {
        onIgnoreTagAttr: (...args) => {
            const name = args?.[1]
            const value = args?.[2]
            if (name === 'class' && value === 'highlight') {
                return name?.concat('="', xss?.escapeAttrValue(value), '"');
            }
        },
    })
}